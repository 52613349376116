import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

import Fragment from './Fragment';
import Home from './Home';

function App() {

  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/fragment" element={<Fragment />} />
    </Routes>
  </BrowserRouter>
}

export default App;
  