import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.scss';

const sections = [
    { name: "CV", slug: "curriculum-vitae" },
    { name: "Contact", slug: "contact" },
    // { name: "Me", slug: "me" }
];

const Header = ({ toggleSection, activeSection }) => {
    const handleClick = section => {
        toggleSection(section);
    };

    const ListItem = section =>
        <li>
            <a
                onClick={() => handleClick(section.slug)}
                className={activeSection == section.slug ? "selected" : ""}
                href={`#${section.slug}`}
            >
                {section.name}
            </a>
        </li>;

    const brown = "#A52A2A";
    return (
        <header id="header">
            <div className="bar" />
            <div className="center">
                <div className="title">
                    <div className="typer">
                        <span style={{color: brown}}>&gt;</span> mattnangle.tech&nbsp;
                    </div>
                </div>
                <nav>
                    <ul>
                        {sections.map(section =>
                            ListItem(section, activeSection, toggleSection)
                        )}
                    </ul>
                </nav>
                <div id="links">
                    <a href="https://www.linkedin.com/in/matt-nangle-46a62099/" target="_blank">
                        <img src="linkedin.png" width={30} />
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;