import React from 'react';
import ReactDOM from 'react-dom';
import Highlighter from "react-highlight-words";
import './index.scss';

const JobListItem = ({data}) => {
    const {
        isNarrow,
        logoImage,
        companyName,
        companyWebsiteURL,
        duration,
        startDate,
        endDate,
        jobTitle,
        location,
        introText,
        responsibilities,
        skillsLearned
    } = data;

    var skills = [
        "Javascript",
        "React.JS",
        "Node.JS",
        "Typescript",
        "Redux",
        "HTML",
        "CSS",
        "XML",
        "SQL",
        "PHP",
        "C#"
    ];

    return (
        <div className="jobListItem">
            <div className="jobListItem_wrapper">
                <div className="jobListItem_info">
                    <div className="jobListItem_dates">
                        <p className="jobListItem_startDate">{startDate} —</p>
                        {endDate && <p className="jobListItem_startDate">{endDate}</p>}
                    </div>

                    {logoImage &&
                        <div className="jobListItem_companyLogo">
                            <img width={isNarrow ? "60" : "100"} src={logoImage} />
                        </div>
                    }
                    <div className="jobListItem_infoText">
                        <ul>
                            <li>{jobTitle}</li>
                            <li>
                                {companyWebsiteURL ? (
                                    <a
                                        href={companyWebsiteURL}
                                        target="_blank"
                                        className="external"
                                    >
                                        {companyName}
                                    </a>
                                ) : (<span>{companyName}</span>)}
                            </li>
                            <li>{duration}</li>
                            <li>{location}</li>
                        </ul>
                    </div>
                </div>
                <p>{introText}</p>
                { responsibilities && <>
                    <h2>Roles / responsibilites:</h2>
                    <ul>
                        {responsibilities.map(responsibility =>    
                            <li key={responsibility}>
                                <Highlighter
                                    highlightClassName="skillHighlight"
                                    searchWords={skills}
                                    autoEscape={true}
                                    textToHighlight={responsibility}
                                /></li>
                        )}
                    </ul>
                </>}
                { skillsLearned && <>
                    <h2>Skills learned:</h2>
                    <ul>
                        {skillsLearned.map(skill =>
                            <li key={skill}>
                                <Highlighter
                                    highlightClassName="skillHighlight"
                                    searchWords={skills}
                                    autoEscape={true}
                                    textToHighlight={skill}
                                />
                            </li>
                        )}
                    </ul>
                </>}
            </div>
        </div>
    );
};

export default JobListItem;