import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Intro from '../Intro';
import AboutMe from '../AboutMe';
import KeySkills from '../KeySkills';
import Experience from '../Experience';
import Contact from '../Contact';
import './App.scss';

function Home() {
  const [activeSection, setActiveSection] = useState();
  const [contactModalOpen, toggleContactModal] = useState(false);
  let pageLoad = false;

  useEffect(() => {
    document.title = "mattnangle.tech - Web Developer / Javascript / ReactJS / Contractor";
    document.fonts.ready.then(function () {
      pageLoad = true;
    });
  }, []);
  
  const toggleModal = enabled => {
    toggleContactModal(enabled);
  };
  const toggleSection = section => {
    setActiveSection(section);
    section == "contact" && toggleModal(true); 
  }
    
  pageLoad = true;
  
  return <div id="container">
        <Header toggleSection={toggleSection} activeSection={activeSection}/>
        <Intro />
        <Experience />
        {contactModalOpen && <Contact toggleModal={toggleModal} />}
    </div>;
}

export default Home;
