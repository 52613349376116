import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import styles from "./index.scss";

const Contact = ({ toggleModal }) => {
    const [nameValue, setNameValue] = useState();
    const [emailValue, setEmailValue] = useState();
    const [messageValue, setMessageValue] = useState();

    // const handleNameChange = (e) => {
    //     setNameValue(e.value);
    // };
    // const handleEmailChange = (e) => {
    //     setEmailValue(e.value);
    // };
    // const handleMessageChange = (e) => {
    //     setMessageValue(e.value);
    // };
    // const resetForm = () => {
    //     setNameValue("");
    //     setEmailValue("");
    //     setMessageValue("");
    // }
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     axios({
    //       method: "POST",
    //       url:"http://localhost:3000/send",
    //       data:  {
    //           nameValue, emailValue, messageValue
    //       }
    //     }).then((response)=>{
    //       if (response.data.status === 'success') {
    //         alert("Message Sent.");
    //         resetForm()
    //       } else if (response.data.status === 'fail') {
    //         alert("Message failed to send.")
    //       }
    //     })
    //   }
    const closeModal = () => {
        toggleModal(false);
    }
    return (
        <section id="contact">
            <div className="closeButton" onClick={closeModal} />
            <div className="header">Get in touch.</div>
            {/* <form id="contact-form" onSubmit={handleSubmit}>
                <input placeholder="Name" onBlur={() => nameValue = ""} type="text" value={nameValue} onChange={handleNameChange} />
                <input placeholder="Email" type="email" value={emailValue} onChange={handleEmailChange} />
                <textarea placeholder="Message" value={messageValue} onChange={handleMessageChange} />
                <input type="submit" value="Send" />
            </form> */}
            <ul>
            <li className="email">
                <a href="mailto:chat@mattnangle.tech" target="_blank">
                    &#9993;
                </a>
            </li>
            <li className="linkedin">
                <a href="https://www.linkedin.com/in/matt-nangle-46a62099/" target="_blank">
                    <img src="linkedin.png" width={30} />
                </a></li>
            </ul>
        </section>
    );
};

export default Contact;