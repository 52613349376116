import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

const Fragment = () => {
  const { unityProvider } = useUnityContext({
    loaderUrl: "Web.loader.js",
    dataUrl: "Web.data.unityweb",
    frameworkUrl: "Web.framework.js.unityweb",
    codeUrl: "Web.wasm.unityweb",
  });

  return <Unity unityProvider={unityProvider} />;
}

export default Fragment;