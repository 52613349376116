import React, { useRef, useEffect } from "react";

const Canvas = props => {
  const { draw, ...rest } = props;
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    var WIDTH = window.innerWidth;
    var HEIGHT = window.innerHeight;
    canvas.width = WIDTH;
    canvas.height = HEIGHT;

    const render = () => {
      draw(context)
    }
    render();
    function onResize() {
      WIDTH = window.innerWidth;
      HEIGHT = window.innerHeight;

      canvas.width = WIDTH;
      canvas.height = HEIGHT;
    }
    window.addEventListener('resize', onResize, false);

  }, [draw])

  return <canvas ref={canvasRef} {...rest} />
}

export default Canvas;