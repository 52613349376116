const jobsDataArray = [
    {
        id: 0,
        logoImage: "asos.png",
        companyName: "ASOS.com",
        companyWebsiteURL: "https://www.asos.com",
        duration: "5 yrs 11 mos",
        startDate: "May 2016",
        endDate: "Feb 2022",
        jobTitle: "Web Engineer",
        location: "London, UK",
        introText: "Over 6 years at ASOS I gained experience working on most aspects of web infrastructure - from delivering front-end React.js applications and features to working on backend deployment pipelines and databases.",
        responsibilities: [
            "Estimation, development, testing and deployment of web apps / features -  e.g. single-page React applications / competition landing pages.",
            "Development / maintaining re-usable homepage components.",
            "Writing unit tests, browser tests with Enzyme and visual tests using Applitools",
            "Mainting cloud service / VMs / APIs in Microsoft Azure.",
            "Data management in Azure Data Studio.",
            "Configuring deployment pipelines using tools TeamCity and Octopus.",
        ],
        skillsLearned: [
            "Gained a lot of experience using React.js and Node.js over the years w/ some Typescript.",
            "Other frameworks learned - KnockoutJS, RequireJS, Handlebars",
            "Testing framweworks - Enzyme, Applitools, Cypress.",
            "DevOps / Continuous in#tegration/deployment practices",
            "HTML canvas minigames 😊",
            "Monitoring and Alerting tools - New Relic / AppInsights",
            "Accessibility - developing for screen readers / WCAG standards",
        ]
    },
    {
        id: 1,
        isNarrow: true,
        logoImage: "stack.jfif",
        companyName: "Stack Agency",
        companyWebsiteURL: "https://www.stackworks.com",
        duration: "1 mo contract",
        startDate: "Apr 2016",
        jobTitle: "Email Developer",
        location: "London, UK",
        introText: "Contracted at Stack and Baber Smith developing responsive email templates for B2B and B2C clients.",
    },
    {
        id: 2,
        isNarrow: true,
        logoImage: "baber.jfif",
        companyName: "Baber Smith",
        companyWebsiteURL: "https://www.babersmith.co.uk/",
        duration: "1 mo contract",
        startDate: "Mar 2016",
        jobTitle: "Developer",
        location: "London, UK",
    },
    {
        id: 3,
        logoImage: "ebay.jfif",
        companyName: "Ebay Enterprise",
        companyWebsiteURL: "https://www.ebayinc.com/company/",
        duration: "11 mos contract",
        startDate: "Apr 2015",
        endDate: "Feb 2016",
        jobTitle: "Developer",
        location: "London, UK",
        introText: "Moved to London to contract at eBay Enterprise - helped me further my experience in marketing/web tech and large-scale campaign delivery.",
        responsibilities: [
            "Developing responsive hybrid HTML email templates, and delivering email campaigns to deployment.", 
            "Landing pages with form integration / preference centres.", 
            "Precision Central 7 platform - data grids / contact lists, audience building and trigger management. Insight reporting.",            
            "XML based triggers / SOAP / SQL for data sets.",   
            "Creating assets / slicing Photoshop files from studio.", 
            "Use of JIRA for organisation / communication with project managers. "
        ],
        skillsLearned: [
            "XML triggers / SQL ",
            "JIRA - Agile workflow",
        ]
    },
    {
        id: 4,
        logoImage: "clevertouch.jfif",
        companyName: "Clevertouch Marketing",
        companyWebsiteURL: "https://clever-touch.com/",
        duration: "11 mos",
        startDate: "Jun 2014",
        endDate: "Apr 2015",
        jobTitle: "Marketing Technology Developer",
        location: "Winchester, UK",
        introText: "After landing back in the UK I worked at Clevertouch for a year, mainly developing emails and data-capture forms / minisites.",
        responsibilities: [
            "Building / updating multi-language HTML email templates",
            "Development of customer landing pages and forms which integrated into a range of marketing software.",
        ],
        skillsLearned: [
            "Javascript / HTML web forms",
            "Custom email HTML templating",
            "Marketing tools Eloqua, Marketo and Pardot"
        ]
    },
    {
        id: 5,
        logoImage: "responsys.jfif",
        companyName: "Responsys Oracle",
        companyWebsiteURL: "Responsys Oracle",
        duration: "1 yrs 2 mos",
        startDate: "May 2016",
        endDate: "Feb 2022",
        jobTitle: "Campaign Specialist",
        location: "Melbourne, AUS",
        introText: "After some time spent travelling, I settled in Melbourne where I worked separately full-time and as a contractor at Responsys Orcale, mainly coding HTML mails and scheduling large batch email campaigns.",
        responsibilities: [
            "HTML development of responsive emails sliced from PSDs.",
            "Managing and scheduling of email campaigns using Responsys platform.",
        ],
        skillsLearned: [
            "Coding email specific HTML markup and CSS.",
            "Responsys delivery platform / Responsys markup language.",
        ]
    },
    {
        id: 6,
        companyName: "George Thomas International",
        duration: "1 yrs 2 mos",
        startDate: "May 2016",
        endDate: "Feb 2022",
        jobTitle: "Web Developer",
        location: "Bournemouth, UK",
        introText: "My first job out of uni - worked on multiple commericial brand websites",
        skillsLearned: [
            "Intermediate CSS / HTML / PHP / Javascript / Basic C#",
        ]
    },

];
export default jobsDataArray;