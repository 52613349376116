import React, { useState, useEffect, useRef } from "react";
import jobsDataArray from "../../data/jobList";
import JobListItem from "../../components/JobListItem";

const jobList = jobsDataArray.map(data =>
	<JobListItem
		key={data.id}
		data={data}
	/>
);

const Experience = () => {
	let scrollY;
	const sectionRef = useRef();
	useEffect(()=> {
		window.addEventListener('scroll', handleScroll);
	});
	const handleScroll = () => {
		console.log(window.scrollY);
		const { offsetTop } = sectionRef.current;
		scrollY = window.scrollY;
	};
	return (
		<section
			id="curriculum-vitae"
			ref={sectionRef}
		>
			<div class={`sectionTitle ${window.scrollY > 600 ? "slide-right" : ""}`}>
				<div id="cv-download">
					<a href="Matt-Nangle-CV.pdf" download>
						<button className="download" />
					</a>
				</div>
				<h1>Curriculum vitae</h1>
			</div>
			<div className="underline" />
			
			<div id="job-list">
				{jobList}
			</div>
		</section>
	)
};

export default Experience;